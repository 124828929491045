<template>
    <Header :isShow="isShow" />
    <div class="wraper">
        <div class="content_box ">
            <div class="content max_small_box" ref="header">
                <div class="list" v-for="(item, index) in detail" data-aos="flip-left" :key="index" :data-aos-delay="(index+1)*200">
                    <div class="img" >
                        <a-image width="100%"  :src="imgUrl + item.image" alt="" />
                    </div>
                    <p class="title">{{item.name}}</p>
                </div>
            </div>
            <div class="pagination max_small_box" v-if="total_page">
                <a-pagination v-model:current="page" :total="total_page" @change="handleChangePage" />
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import AboutTop from '../../components/about/AboutTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理函数
const detailEffect = (route) => {
    //详情
    const detail = ref()

    //页数
    const page = ref(1)

    //总页数
    const total_page = ref()

    //获取详情
    const getDetail = async (id, page_id) => {
        detail.value = []
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
            page: page.value
        }
        await post('/about/get_about', postData).then((res) => {
            // console.log('资质', res)
            if(res.code == 1) {
                detail.value = res.data.data,
                total_page.value = res.data.total
            }
        })
    }

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
    }

    watchEffect(() =>{
        getDetail(route.params.id, route.params.page_id)
    })

    return { detail, getDetail, page, total_page, handleChangePage }
}

export default {
    name: 'Qualification',
    components: { Header, Footer, FloatingFrame, AboutTop },
    setup() {
        const  route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理函数
        const { detail, page, total_page, handleChangePage } = detailEffect(route)

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail, page, total_page, handleChangePage }
    }
}
</script>

<style lang="scss" scoped>
.content_box{
    margin-top: 70px;
}
.content{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .list{
        width: 17%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        background-color: #F4F4F4;
        margin-bottom: 50px;
        border: 2px solid #F4F4F4;
         margin-right: 3%;
        &:nth-child(5n){
            margin-right: 0%;
        }
        &:hover{
            .img{
                >>> .ant-image-img{
                    transform: scale(1.1);
                }
            }
        }
        @media screen and (max-width: 1200px){
            width: 45%;
            height: 350px;
            &:nth-child(5n){
                margin-right: 3%;
            }
            &:nth-child(2n){
                margin-right: 0%;
            }
        }
        @media screen and (max-width: 900px){
            width: 45%;
            height: 200px;
        }
        .img{
            width: 100%;
            height: 160px;
            overflow: hidden;
            cursor: pointer;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 1200px){
                height: 250px;
            }
            >>> .ant-image{
                height: 100%;
                display: block;
                transition: .3s all;

                /* >>> .ant-image-img{
                    object-fit: scale-down;
                } */
            }
        }
            
        .title{
            color: rgba(48, 90, 168, 100);
            font-size: 16px;
            margin: 25px;
            line-height: 24px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            @media screen and (max-width: 900px){
                margin: 15px;
                font-size: .14rem;
                overflow:inherit;
            }
        }
    }
}
.pagination{
    text-align: center;
    padding-top: 80px;
    display: block;
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>

<style >
/* .ant-image{
    display: block;
    height: 100%;
}
.ant-image:hover .ant-image-img{
    transform: scale(1.1);
}
.ant-image-img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    transition: .3s all;
} */
</style>